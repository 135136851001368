import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {Link, useParams} from "react-router-dom";
import { ToWords } from 'to-words';
import {isIP} from 'is-ip';


const IpAddressLocation = (props) => {

  const {ip} = useParams();
  const toWords = new ToWords({localeCode: 'en-US'});
  const [ipData, setIpData] = useState(false)

  useEffect(() => {
    
    if (isIP(ip)) {
     
      axios.get(`https://ipapi.co/${ip}/json/`)
      .then(({data}) => {
        setIpData(data)
      })
      .catch((error) => {
        console.log('====================================');
        console.log(error);
        console.log('====================================');
      })
      
    }

  }, [])
  

  return (
    <>

        {ipData && 
          <div className="max-w-4xl border rounded-xl my-24 mx-auto relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <tbody>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Company
                          </th>
                          <td className="px-6 py-4">
                              {ipData.org}
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            City
                          </th>
                          <td className="px-6 py-4">
                              {ipData.city} ({ipData.postal})
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            State
                          </th>
                          <td className="px-6 py-4">
                              {ipData.region}
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Country
                          </th>
                          <td className="px-6 py-4 flex items-center">
                              {ipData.country_name} ({ipData.country_code}) <img className='ml-5 h-6' src={`https://flagsapi.com/${ipData.country_code}/flat/64.png`} />
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Currency
                          </th>
                          <td className="px-6 py-4">
                              {ipData.currency_name} ({ipData.currency})
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Country Capital
                          </th>
                          <td className="px-6 py-4">
                              {ipData.country_capital}
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Country Calling Code
                          </th>
                          <td className="px-6 py-4">
                              {ipData.country_calling_code}
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Country Population
                          </th>
                          <td className="px-6 py-4">
                              {toWords.convert(ipData.country_population)} ({ipData.country_population})
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Country TLD
                          </th>
                          <td className="px-6 py-4">
                              {ipData.country_tld}
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Location
                          </th>
                          <td className="px-6 py-4">
                              {ipData.latitude}, {ipData.longitude}
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Autonomous System
                          </th>
                          <td className="px-6 py-4">
                              {ipData.asn}
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            IP
                          </th>
                          <td className="px-6 py-4">
                              {ipData.ip}
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            IP Version
                          </th>
                          <td className="px-6 py-4">
                              {ipData.version}
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Timezone
                          </th>
                          <td className="px-6 py-4">
                              {ipData.timezone} (UTC {ipData.utc_offset})
                          </td>
                      </tr>

                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Languages
                          </th>
                          <td className="px-6 py-4">
                              {ipData.languages.split(",").map( (lang, index) => {
                                return (
                                  <React.Fragment key={index}>{lang}<br /></React.Fragment>
                                )
                              } )}
                          </td>
                      </tr>

                      <tr className="bg-white dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Network
                          </th>
                          <td className="px-6 py-4">
                              <Link className='hover:underline' to={`/ip/${ipData.network}`}>{ipData.network}</Link>
                          </td>
                      </tr>

                  </tbody>
              </table>
          </div>
        }


    </>
  )
}

export default IpAddressLocation