import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { Netmask } from 'netmask';
import { ToWords } from 'to-words';
import { isIP } from 'is-ip';

const IpAddressCIDR = () => {

  const {ip, cidr} = useParams();

  if (isIP(ip)) {

    const fullIp = `${ip}/${cidr}`
    
    const netmask = new Netmask(fullIp)
    const towords = new ToWords({localeCode: "en-US"})


    const allAds = []

    netmask.forEach((ip, long, index) => {
      if (index <= 499) {
        allAds.push(ip)
      }
      else {
        return;
      }
    })

    return (
      <>
  
        <div className="max-w-4xl border rounded-xl my-24 mx-auto relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <tbody>
  
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          Base
                        </th>
                        <td className="px-6 py-4">
                          {netmask.base}
                        </td>
                    </tr>
  
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          Bitmask
                        </th>
                        <td className="px-6 py-4">
                          {netmask.bitmask}
                        </td>
                    </tr>
  
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          Broadcast
                        </th>
                        <td className="px-6 py-4">
                          {netmask.broadcast}
                        </td>
                    </tr>
  
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          First
                        </th>
                        <td className="px-6 py-4">
                          <Link className='hover:underline' to={`/ip/${netmask.first}`}>{netmask.first}</Link>
                        </td>
                    </tr>
  
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          Last
                        </th>
                        <td className="px-6 py-4">
                          <Link className='hover:underline' to={`/ip/${netmask.last}`}>{netmask.last}</Link>
                        </td>
                    </tr>
  
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          Hostmask
                        </th>
                        <td className="px-6 py-4">
                          {netmask.hostmask}
                        </td>
                    </tr>
  
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          Mask
                        </th>
                        <td className="px-6 py-4">
                          {netmask.mask}
                        </td>
                    </tr>
  
                    <tr className="bg-white dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          Size
                        </th>
                        <td className="px-6 py-4">
                          {towords.convert(netmask.size)} ({netmask.size})
                        </td>
                    </tr>
  
                </tbody>
            </table>
        </div>
  
  
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  
          <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 xl:grid-cols-6 gap-3 sm:gap-6">
  
            {allAds.map((ip, index) => {
              return (
                
                <Link to={`/ip/${ip}`} key={index} className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                  <div className="p-4 md:p-5">
                    <div className="flex justify-center items-center">
                      <div>
                        <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                        {ip}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })}
  
          </div>
  
        </div>
  
      </>
    )

  }

  
}

export default IpAddressCIDR