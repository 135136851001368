import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import isValidDomain from 'is-valid-domain';
import axios from 'axios';

const DomainDNSLookup = () => {

    const {domain} = useParams();

    const [nameservers, setnameservers] = useState([])
    const [arecord, setarecord] = useState([])
    const [aaaarecord, setaaaarecord] = useState([])
    const [txtrecord, settxtrecord] = useState([])
    const [soarecord, setsoarecord] = useState([])
    const [mxrecord, setmxrecord] = useState([])

    useEffect(() => {
      
        if (isValidDomain(domain, {subdomain: false})) {
        
            axios.get(`https://dns.google.com/resolve?name=${domain}&type=NS`)
            .then(({data}) => {
                if (data.Status === 0 && Object.hasOwn(data, 'Answer')) {
                    const entries = []
                    for (let ns = 0; ns < data.Answer.length; ns++) {
                        const n = data.Answer[ns];
                        entries.push({title: n.name, ttl: n.TTL, value: n.data})
                    }
    
                    setnameservers(entries)
                }
            })
    
        }

    }, [])

    useEffect(() => {
      
        if (isValidDomain(domain, {subdomain: false})) {
        
            axios.get(`https://dns.google.com/resolve?name=${domain}&type=A`)
            .then(({data}) => {
                if (data.Status === 0 && Object.hasOwn(data, 'Answer')) {
                    const entries = []
                    for (let ns = 0; ns < data.Answer.length; ns++) {
                        const n = data.Answer[ns];
                        entries.push({title: n.name, ttl: n.TTL, value: n.data})
                    }
    
                    setarecord(entries)
                }
            })
    
        }

    }, [])

    useEffect(() => {
      
        if (isValidDomain(domain, {subdomain: false})) {
        
            axios.get(`https://dns.google.com/resolve?name=${domain}&type=AAAA`)
            .then(({data}) => {
                if (data.Status === 0 && Object.hasOwn(data, 'Answer')) {
                    const entries = []
                    for (let ns = 0; ns < data.Answer.length; ns++) {
                        const n = data.Answer[ns];
                        entries.push({title: n.name, ttl: n.TTL, value: n.data})
                    }
    
                    setaaaarecord(entries)
                }
            })
    
        }

    }, [])

    useEffect(() => {
      
        if (isValidDomain(domain, {subdomain: false})) {
        
            axios.get(`https://dns.google.com/resolve?name=${domain}&type=TXT`)
            .then(({data}) => {
                if (data.Status === 0 && Object.hasOwn(data, 'Answer')) {
                    const entries = []
                    for (let ns = 0; ns < data.Answer.length; ns++) {
                        const n = data.Answer[ns];
                        entries.push({title: n.name, ttl: n.TTL, value: n.data})
                    }
    
                    settxtrecord(entries)
                }
            })
    
        }

    }, [])

    useEffect(() => {
      
        if (isValidDomain(domain, {subdomain: false})) {
        
            axios.get(`https://dns.google.com/resolve?name=${domain}&type=SOA`)
            .then(({data}) => {
                if (data.Status === 0 && Object.hasOwn(data, 'Answer')) {
                    const entries = []
                    for (let ns = 0; ns < data.Answer.length; ns++) {
                        const n = data.Answer[ns];
                        entries.push({title: n.name, ttl: n.TTL, value: n.data})
                    }
    
                    setsoarecord(entries)
                }
            })
    
        }

    }, [])

    useEffect(() => {
      
        if (isValidDomain(domain, {subdomain: false})) {
        
            axios.get(`https://dns.google.com/resolve?name=${domain}&type=MX`)
            .then(({data}) => {
                if (data.Status === 0 && Object.hasOwn(data, 'Answer')) {
                    const entries = []
                    for (let ns = 0; ns < data.Answer.length; ns++) {
                        const n = data.Answer[ns];
                        entries.push({title: n.name, ttl: n.TTL, value: n.data})
                    }
                    setmxrecord(entries)
                }
            })
    
        }

    }, [])
    
    
  return (
    <>

      <div className="max-w-4xl border rounded-xl my-24 mx-auto relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Property
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                        TTL
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Value
                    </th>
                </tr>
            </thead>
              <tbody>

                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Domain
                    </th>
                    <td className="px-6 py-4">
                    {domain}
                    </td>
                    <td className="px-6 py-4">
                    </td>
                    <td className="px-6 py-4">
                    </td>
                </tr>

                {nameservers && nameservers.map((ns, index) => {
                    return (

                        <tr key={index} className={`bg-white dark:bg-gray-800 dark:border-gray-700 ${index === nameservers.length -1 ? "border-b" : "" }`}>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {index === 0 ? "Nameservers" : ""}
                            </th>
                            <td className="px-6 py-4">
                                {ns.title}
                            </td>
                            <td className="px-6 py-4">
                                {ns.ttl}
                            </td>
                            <td className="px-6 py-4">
                                {ns.value}
                            </td>
                        </tr>
                        
                    )
                })}

                {arecord && arecord.map((ns, index) => {
                    return (

                        <tr key={index} className={`bg-white dark:bg-gray-800 dark:border-gray-700 ${index === arecord.length -1 ? "border-b" : "" }`}>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {index === 0 ? "A" : ""}
                            </th>
                            <td className="px-6 py-4">
                                {ns.title}
                            </td>
                            <td className="px-6 py-4">
                                {ns.ttl}
                            </td>
                            <td className="px-6 py-4">
                                {ns.value}
                            </td>
                        </tr>
                        
                    )
                })}

                {aaaarecord && aaaarecord.map((ns, index) => {
                    return (

                        <tr key={index} className={`bg-white dark:bg-gray-800 dark:border-gray-700 ${index === aaaarecord.length -1 ? "border-b" : "" }`}>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {index === 0 ? "AAAA" : ""}
                            </th>
                            <td className="px-6 py-4">
                                {ns.title}
                            </td>
                            <td className="px-6 py-4">
                                {ns.ttl}
                            </td>
                            <td className="px-6 py-4">
                                {ns.value}
                            </td>
                        </tr>
                        
                    )
                })}

                {txtrecord && txtrecord.map((ns, index) => {
                    return (

                        <tr key={index} className={`bg-white dark:bg-gray-800 dark:border-gray-700 ${index === txtrecord.length -1 ? "border-b" : "" }`}>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {index === 0 ? "TXT" : ""}
                            </th>
                            <td className="px-6 py-4">
                                {ns.title}
                            </td>
                            <td className="px-6 py-4">
                                {ns.ttl}
                            </td>
                            <td className="px-6 py-4">
                                {ns.value}
                            </td>
                        </tr>
                        
                    )
                })}

                {soarecord && soarecord.map((ns, index) => {
                    return (

                        <tr key={index} className={`bg-white dark:bg-gray-800 dark:border-gray-700 ${index === soarecord.length -1 ? "border-b" : "" }`}>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {index === 0 ? "SOA" : ""}
                            </th>
                            <td className="px-6 py-4">
                                {ns.title}
                            </td>
                            <td className="px-6 py-4">
                                {ns.ttl}
                            </td>
                            <td className="px-6 py-4">
                                {ns.value}
                            </td>
                        </tr>
                        
                    )
                })}

                {mxrecord && mxrecord.map((ns, index) => {
                    return (

                        <tr key={index} className={`bg-white dark:bg-gray-800 dark:border-gray-700`}>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {index === 0 ? "MX" : ""}
                            </th>
                            <td className="px-6 py-4">
                                {ns.title}
                            </td>
                            <td className="px-6 py-4">
                                {ns.ttl}
                            </td>
                            <td className="px-6 py-4">
                                {ns.value}
                            </td>
                        </tr>
                        
                    )
                })}


              </tbody>
          </table>
      </div>

    </>
  )
}

export default DomainDNSLookup