import axios from 'axios'
import isValidDomain from 'is-valid-domain';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import domainnameparser from "domain-name-parser"
import moment from 'moment';
import formatDistance from 'date-fns/formatDistance';

const WHOISLookup = () => {

    const {domain} = useParams();

    const [dstatus, setDstatus] = useState([])
    const [dnameservers, setDnameservers] = useState([])
    const [devents, setDevents] = useState([])
    const [dentities, setDentities] = useState([])
    const [dsec, setDsec] = useState(false)
    const [ddata, setDdata] = useState({})

    useEffect(() => {
    
        if (isValidDomain(domain, {subdomain: false})) {

            const tld = domainnameparser(domain).tld
         
            if (tld === "com") {
                axios.get(`https://rdap.verisign.com/com/v1/domain/${domain}`)
                .then(({data}) => {

                    let registrarUrl = ""

                    for (let l = 0; l < data.links.length; l++) {
                        const link = data.links[l];
                        if (link.rel === "related") {
                            registrarUrl = link.href
                        }   
                    }

                    axios.get(registrarUrl)
                    .then(({data}) => {

                        setDstatus(data.status)
                        setDnameservers(data.nameservers)
                        setDevents(data.events)
                        setDentities(data.entities)
                        setDsec(data.secureDNS.delegationSigned)
                        setDdata(data)
                        console.log(data);

                    })

                  

                })
                .catch((error) => {
                  console.log('====================================');
                  console.log(error);
                  console.log('====================================');
                })
            }
          
        }
    
      }, [])

  return (
    <>
    
        <div className="max-w-4xl border rounded-xl my-24 mx-auto relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <tbody>

                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Domain
                        </th>
                        <td className="px-6 py-4">
                            {ddata.ldhName}
                        </td>
                    </tr>

                    {dentities.map( (entity, index) => {
                        switch (entity.roles[0]) {
                            case "registrar":
                                return (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Registrar
                                        </th>
                                        <td className="px-6 py-4">
                                            {entity.vcardArray[1].map( (dt, index) => {
                                                console.log(typeof(dt[3]));
                                                if (dt[2] === "text" && index !== 0 && typeof(dt[3]) === "string" && dt[0] === "fn") {
                                                    return (
                                                        <React.Fragment key={index}>{dt[3]}<br /></React.Fragment>
                                                    )
                                                }
                                            } )}
                                        </td>
                                    </tr>
                                )
                            case "registrant":
                                return (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Registrant
                                        </th>
                                        <td className="px-6 py-4">
                                            {entity.vcardArray[1].map( (dt, index) => {
                                                console.log(typeof(dt[3]));
                                                if (dt[2] === "text" && index !== 0 && typeof(dt[3]) === "string" && dt[0] === "fn") {
                                                    return (
                                                        <React.Fragment key={index}>{dt[3]}<br /></React.Fragment>
                                                    )
                                                }
                                                else if (dt[2] === "text" && index !== 0 && typeof(dt[3]) === "object") {
                                                    dt[3].map( (ad, index2) => {
                                                        return <React.Fragment key={index2}>{ad}</React.Fragment>
                                                    } )
                                                }
                                            } )}
                                        </td>
                                    </tr>
                                )
                            case "administrative":
                                return (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Admin
                                        </th>
                                        <td className="px-6 py-4">
                                            {entity.vcardArray[1].map( (dt, index) => {
                                                console.log(typeof(dt[3]));
                                                if (dt[2] === "text" && index !== 0 && typeof(dt[3]) === "string" && dt[0] === "fn") {
                                                    return (
                                                        <React.Fragment key={index}>{dt[3]}<br /></React.Fragment>
                                                    )
                                                }
                                                else if (dt[2] === "text" && index !== 0 && typeof(dt[3]) === "object") {
                                                    dt[3].map( (ad, index2) => {
                                                        return <React.Fragment key={index2}>{ad}</React.Fragment>
                                                    } )
                                                }
                                            } )}
                                        </td>
                                    </tr>
                                )
                            case "technical":
                                return (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Technical
                                        </th>
                                        <td className="px-6 py-4">
                                            {entity.vcardArray[1].map( (dt, index) => {
                                                console.log(typeof(dt[3]));
                                                if (dt[2] === "text" && index !== 0 && typeof(dt[3]) === "string" && dt[0] === "fn") {
                                                    return (
                                                        <React.Fragment key={index}>{dt[3]}<br /></React.Fragment>
                                                    )
                                                }
                                                else if (dt[2] === "text" && index !== 0 && typeof(dt[3]) === "object") {
                                                    dt[3].map( (ad, index2) => {
                                                        return <React.Fragment key={index2}>{ad}</React.Fragment>
                                                    } )
                                                }
                                            } )}
                                        </td>
                                    </tr>
                                )
                        
                            default:
                                return ""
                        }
                    } )}

                    {devents.map((xevent, index) => {
                        const apiDate = new Date(xevent.eventDate);
                        const relativeTimeString = formatDistance(apiDate, new Date());
                        switch (xevent.eventAction) {
                            case "expiration":
                                return (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Expiration Date
                                        </th>
                                        <td className="px-6 py-4">
                                            {moment(xevent.eventDate).format('MMMM DD, YYYY')} (in {relativeTimeString})
                                        </td>
                                    </tr>
                                )
                            case "registration":
                                return (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Registration Date
                                        </th>
                                        <td className="px-6 py-4">
                                            {moment(xevent.eventDate).format('MMMM DD, YYYY')} ({relativeTimeString} ago)
                                        </td>
                                    </tr>
                                )
                            case "last changed":
                                return (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Last Changed
                                        </th>
                                        <td className="px-6 py-4">
                                            {moment(xevent.eventDate).format('MMMM DD, YYYY')} ({relativeTimeString} ago)
                                        </td>
                                    </tr>
                                )
                            case "last update of RDAP database":
                                return (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Last Updated
                                        </th>
                                        <td className="px-6 py-4">
                                            {moment(xevent.eventDate).format('MMMM DD, YYYY')} ({relativeTimeString} ago)
                                        </td>
                                    </tr>
                                )
                        
                            default:
                                return ""
                        }
                    })}
                    
                    {dnameservers.map( (ns, index) => {
                        return (
                            <tr key={index} className={`bg-white dark:bg-gray-800 dark:border-gray-700 ${index === dnameservers.length -1 ? "border-b" : "" }`}>
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {index === 0 ? "Nameservers" : ""}
                                </th>
                                <td className="px-6 py-2">
                                    {ns.ldhName}
                                </td>
                            </tr>
                        )
                    } )}
                    
                    {dstatus.map( (status, index) => {
                        return (
                            <tr key={index} className={`bg-white dark:bg-gray-800 dark:border-gray-700 ${index === dstatus.length -1 ? "border-b" : "" }`}>
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {index === 0 ? "Status" : ""}
                                </th>
                                <td className="px-6 py-2">
                                    {status}
                                </td>
                            </tr>
                        )
                    } )}
                    

                    <tr className="bg-white dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            DNSSEC
                        </th>
                        <td className="px-6 py-4">
                            {dsec ? "Signed" : "Unsigned"}
                        </td>
                    </tr>


                </tbody>
            </table>
        </div>

    </>
  )
}

export default WHOISLookup