import React from 'react'
import HandleRoutes from './components/Functional/HandleRoutes';
import('preline')

const App = () => {

  return (
    <>

      <HandleRoutes />

    </>
  )

}

export default App