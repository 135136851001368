import axios from 'axios'
import isValidDomain from 'is-valid-domain'
import { ParseResultType, parseDomain } from 'parse-domain'
import React, { useState } from 'react'

const tldPriceList = {
    "com" : {p: 949,m: 400},
    "in" : {p: 699,m: 400},
    "shop" : {p: 124,m: 500},
    "me" : {p: 170,m: 500},
    "co" : {p: 899,m: 400},
    "club" : {p: 409,m: 400},
    "org" : {p: 749,m: 400},
    "net" : {p: 1149,m: 400},
    "info" : {p: 319,m: 400},
    "site" : {p: 299,m: 400},
    "store" : {p: 529,m: 400},
    "social" : {p: 416,m: 400},
    "asia" : {p: 416,m: 400},
    "app" : {p: 829,m: 400},
}

const tldPrices = (tld) => {

    const dollorRate = 85

    const domainPrice = (tldPriceList[tld].p / dollorRate) + ( (tldPriceList[tld].p * ( 18 / 100 ) / dollorRate)  )
    console.log(domainPrice);
    const finalPrice = Math.ceil(domainPrice + ( domainPrice * ( tldPriceList[tld].m / 100 ) ) )
    console.log(finalPrice);
    const withTaxes = Math.ceil(finalPrice + ( finalPrice * ( 18 / 100 ) ))
    console.log(withTaxes);

    return withTaxes
}

const DomainRegistrationChecker = () => {

    const [domainList, setdomainList] = useState([])

    const checker = async (domain) => {
        try {
            const response = await axios.get(`https://dns.google.com/resolve?name=${domain}&type=NS`);
            const data = response.data;
    
            if (data.Status === 3) {
                return true;
            } 
            else {
                return false;
            }

        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const getPrices = (domain) => {

        const parseResult = parseDomain(domain);

        if (parseResult.type === ParseResultType.Listed) {

            const { topLevelDomains } = parseResult;

            if (tldPriceList.hasOwnProperty(topLevelDomains)) {
                return `$ ${tldPrices(topLevelDomains)}`
            }

            return "Contact Us"

        } else {
            return "Contact Us"
        }
    }
    

    const handleClick = async () => {

        const domain = document.getElementById("domain-srch").value.toLowerCase()

        if (isValidDomain(domain, {subdomain: false})) {
            const check = await checker(domain)
            if (check) {
                setdomainList((prevState) => [...prevState, { domain, availability: "Available", price: getPrices(domain) }]);
            }
            else{
                setdomainList((prevState) => [...prevState, { domain, availability: "Not Available", price: "" }]);
            }
        }
        else {
            setdomainList((prevState) => [...prevState, { domain, availability: "Check the domain", price: "" }]);
        }

    }

  return (
    <>

        
        <div className='container mx-auto px-2 mt-24'>   
            <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
            <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="search" id="domain-srch" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Domains" required autoComplete='off' autoCapitalize='off' />
                <button type="submit" onClick={handleClick} className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Check</button>
            </div>
        </div>


        <div className="max-w-4xl border xl:rounded-xl my-24 mx-auto relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Domain
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Availability
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Price
                    </th>
                </tr>
            </thead>
              <tbody>

                {domainList.reverse().map( (set, index) => {
                    return (
                        <tr className={`bg-white ${index === domainList.reverse().length -1 ? "" : "border-b"} dark:bg-gray-800 dark:border-gray-700`} key={index}>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {set.domain}
                            </th>
                            <td className="px-6 py-4">
                                {set.availability}
                            </td>
                            <td className="px-6 py-4">
                                {set.price}
                            </td>
                        </tr>
                    )
                } )}

                </tbody>
            </table>
        </div>

        
    </>
  )
}

export default DomainRegistrationChecker