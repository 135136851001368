import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomePage from '../../pages/HomePage';
import IpAddressLocation from '../Elements/IpAddressLocation';
import IpAddressCIDR from '../Elements/IpAddressCIDR';
import DomainDNSLookup from '../Elements/DomainDNSLookup';
import WHOISLookup from '../Elements/WHOISLookup';
import Navbar from '../InPage/Navbar';
import CrashTools from '../../pages/CrashTools';
import HeatTools from '../../pages/HeatTools';
import DomainRegistrationChecker from '../../pages/DomainRegistrationChecker';
import BrowserFingureprintTool from '../../pages/BrowserFingureprintTool';

const HandleRoutes = () => {
  return (
    <>
    
        <BrowserRouter>

          <Navbar />
            
            <Routes>

                <Route path='/' element={<HomePage />} />

                <Route path='/ip/:ip' element={<IpAddressLocation />} />
                <Route path='/ip/:ip/:cidr' element={<IpAddressCIDR />} />

                <Route path='/dns/:domain' element={<DomainDNSLookup />} />
                <Route path='/whois/:domain' element={<WHOISLookup />} />
                
                <Route path='/tools/crash' element={<CrashTools />} />
                <Route path='/tools/heat' element={<HeatTools />} />

                <Route path='/generator/browser-fingerprint' element={<BrowserFingureprintTool />} />

                <Route path='/checker/domains-registration' element={<DomainRegistrationChecker />} />

            </Routes>

        </BrowserRouter>
    
    </>
  )
}

export default HandleRoutes