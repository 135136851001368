import Fuse from 'fuse.js'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'


const HomePage = () => {

  const data = {
    cards: [
      {title: "IP Geolization", description: "Geolization of any IP address", link: "/generator/ip-geolization"},
      {title: "IP netmask details", description: "Subnet details of IP CID Block", link: "/calculator/ip-netmask-calculator"},
      {title: "User Agent parser", description: "Extract all the info from a user agent", link: "/parser/user-agent-parser"},
      {title: "Domain WHOIS Lookup", description: "WHOIS data for domains", link: "/lookup/domain-whois"},
      {title: "Domain DNS Lookup", description: "Lookup DNS records of domains", link: "/lookup/domain-dns"},
      {title: "Browser Fingerprint Generator", description: "Generate Fingerprint of your browser", link: "/generator/browser-fingerprint"},
      {title: "Incognito Detector", description: "Detect if you're using Incognito mode", link: "/lookup/domain-dns"},
      {title: "Crash the Device", description: "This tool can crash your device", link: "/tools/crash"},
      {title: "Heat the Device", description: "This tool can heat your device", link: "/tools/heat"},
      {title: "Domain Registration Check", description: "Check if a domain is taken", link: "/checker/domains-registration"},
    ]
  }

  const options = {
    keys: ['title', 'description']
  }

  const fuse = new Fuse(data.cards, options)

  

  const [cards, setcards] = useState(data.cards)

  const handleSearch = (e) => {
    const input = e.target.value

    if (input === "") {
      setcards(data.cards)
    }
    else {
      const results = fuse.search(input)
      if (results.length > 0) {
        setcards(results)
      }
      else {
        setcards(data.cards)
      }
    }

  }


  return (
    <>


      <div className='container mt-24 mx-auto'>

        <div className="relative mx-5">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
            </div>
            <input onInput={handleSearch} type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search tools" required autoComplete='off' autoCapitalize='off' />
        </div>

      </div>



      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

        <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 sm:gap-6">
          

          {cards.map( (card, index) => {
            return (
              
              <Link to={card.link || card.item.link} className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" key={index}>
                <div className="p-4 md:p-5">
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                        {card.title || card.item.title}
                      </h3>
                      <p className="text-sm text-gray-500">
                        {card.description || card.item.description}
                      </p>
                    </div>
                    <div className="ps-3">
                      <svg className="flex-shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6"/></svg>
                    </div>
                  </div>
                </div>
              </Link>

            )
          } )}
          
        </div>

      </div>

    </>
  )
}

export default HomePage