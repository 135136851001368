import React, { useEffect, useState } from 'react'
import getBrowserFingerprint from 'get-browser-fingerprint';
import { detectIncognito } from "detectincognitojs";


const BrowserFingureprintTool = () => {

    const [fingurePrint, setFingurePrint] = useState("Generating")
    const [incognito, setIncognito] = useState({private: "Detecting", browser: "Detecting"})

    useEffect(() => {
        const fingerprint = getBrowserFingerprint();
        setFingurePrint(fingerprint)

        detectIncognito().then((result) => {
            setIncognito({browser: result.browserName, private: result.isPrivate})
        });

    }, [])
    

  return (
    <>
        
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 flex flex-col xl:flex-row justify-between mx-auto">

                <div>
                    <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-blue-600">{incognito.private ? "True" : "False"}</p>
                    <p className="mt-1 text-gray-500">Incognito</p>
                </div>

                <div>
                    <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-blue-600">{fingurePrint}</p>
                    <p className="mt-1 text-gray-500">Fingerprint</p>
                </div>

                <div>
                    <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-blue-600">{incognito.browser}</p>
                    <p className="mt-1 text-gray-500">Browser</p>
                </div>

        </div>

    </>
  )
}

export default BrowserFingureprintTool